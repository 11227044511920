import { apiRequest } from '(apis)/api-interface/api-handler';
import { Divider } from 'antd';
import React from 'react'
import { useEffect, useState } from "react";
import toast from 'react-hot-toast';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ManageIcon } from 'shared-components/ui/Icons';
import { formatNumberWithCommas } from 'shared-pages/latest-commission/utils';

const COLORS = ['#8884d8', '#82ca9d', '#ffc658'];

export default function AuditQuestionStats() {
  const [loading, setLoading] = useState<boolean>(false);
  const [topTenAttempted, setTopTenAttempted] = useState([]);
  const [difficultyStats, setDifficultyStats] = useState([]);
  const [Information, setInformation] = useState([{
    label: "Total Questions Reported",
    value1: 0,
  },
  {
    label: "Total Questions Reported Today",
    value1: 0,
  },
  {
    label: "Total Questions Reported This Week",
    value1: 0,
  },
  {
    label: "Total Questions Reported This Month",
    value1: 0,
  },
  ])

  const [AttemptedQuestions, setAttemptedQuestions] = useState([
    {
      label: "Total Questions Options Chosen",
      value1: 0,
    },
    {
      label: "Total Questions Options Chosen Today",
      value1: 0,
    },
    {
      label: "Total Questions",
      value1: 0,
    }
  ])
  const [ReportedQuestions, setReportedQuestions] = useState([])
  const [ReportStatistics, setReportStatistics] = useState([])
  const [SubjectiveDoubtsCount, setSubjectiveDoubtsCount] = useState([])

  const [YearWiseAddEdit, setYearWiseAddEdit] = useState([])
  const [MonthWiseAddEdit, setMonthWiseAddEdit] = useState([])
  const [DayWiseAddEdit, setDayWiseAddEdit] = useState([])
  const [AuthorsAndStats, setAuthorsAndStats] = useState([])

  const filterOutliers = (data, key) => {
    const values = data.map(item => item[key]);
    const q1 = values.sort((a, b) => a - b)[Math.floor(values.length / 4)];
    const q3 = values.sort((a, b) => a - b)[Math.floor(values.length * 3 / 4)];
    const iqr = q3 - q1;
    const lowerBound = q1 - 1.5 * iqr;
    const upperBound = q3 + 1.5 * iqr;

    return data.filter(item => item[key] >= lowerBound && item[key] <= upperBound);
  };

  const filteredYearWiseAddEdit = filterOutliers(YearWiseAddEdit, 'adds');
  const filteredMonthWiseAddEdit = filterOutliers(MonthWiseAddEdit, 'adds');
  const filteredDayWiseAddEdit = filterOutliers(DayWiseAddEdit, 'adds');

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await apiRequest("OtherStatistics");
      if (res?.data?.success) {
        const sortedTopTen = res?.data?.Top10AttemptedQuestions.sort((a, b) => b.count - a.count);
        const sortedDifficultyStats = res?.data?.DifficultyStats
        setTopTenAttempted(sortedTopTen);
        setDifficultyStats(sortedDifficultyStats);        
        setReportedQuestions(res?.data?.MostReportedQuestions?.slice(0, 5))
        setReportStatistics(res?.data?.ReportStatusCounts)
        setYearWiseAddEdit(res?.data?.yearWiseAddVSEdit)
        setMonthWiseAddEdit(res?.data?.monthWiseAddVSEdit)
        setDayWiseAddEdit(res?.data?.dayWiseAddVSEdit)
        setAuthorsAndStats(res?.data?.AuthorsAndStats)
        setInformation([{
          label: "Total Questions Reported",
          value1: res?.data?.totalQuestionsReported,
        },
        {
          label: "Total Questions Reported Today",
          value1: res?.data?.totalQuestionsReportedToday,
        },
        {
          label: "Total Questions Reported This Week",
          value1: res?.data?.totalQuestionsReportedThisWeek,
        },
        {
          label: "Total Questions Reported This Month",
          value1: res?.data?.totalQuestionsReportedThisMonth,
        },
        ])

        setAttemptedQuestions([{
          label: "Total Questions Attempted",
          value1: res?.data?.totalQuestionsOptionsChosen,
        },
        {
          label: "Total Questions Attempted Today",
          value1: res?.data?.totalQuestionsOptionsChosenToday,
        },
        {
          label: "Total Questions",
          value1: res?.data?.TotalQuestions,
        }
        ])
        setSubjectiveDoubtsCount(res?.data?.SubjectiveDoubtsCount)
      } else {
        toast.error(res?.data?.message)
      }
      console.log("API Response: ", res);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="mx-10 font-bold text-2xl">Questions Statistics</div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>

          <div className="mt-10">
            <h2 className="font-bold text-xl text-gray-800 mb-10">Difficulty Stats</h2>
            <div className='grid grid-cols-3 gap-4'>
              {difficultyStats.map((difficulty, index) => (
                <RenderStatCard
                  item={{
                    label: difficulty?.difficulty,
                    value1: difficulty?.count,
                  }}
                  key={index}
                />
              ))}
            </div>
          </div>

          <div className='mt-10'>
            <h2 className="font-bold text-xl text-gray-800 mb-10">Reported Questions</h2>
            <div className='grid grid-cols-3 gap-4'>
              {Information.map((item, index) => (
                <RenderStatCard
                  item={item}
                  key={index}
                />
              ))}
            </div>

            <h2 className="font-bold text-xl text-gray-800 mb-10">Reported Questions</h2>
            <div className='grid grid-cols-3 gap-4'>
              {ReportStatistics.map((item, index) => (
                <RenderStatCard
                  item={{
                    label: item?._id,
                    value1: item?.count,
                  }}
                  key={index}
                />
              ))}
            </div>

          </div>

          <div className='mt-10'>
            <h2 className="font-bold text-xl text-gray-800 mb-10">Attempted Questions</h2>
            <div className='grid grid-cols-3 gap-4'>
              {AttemptedQuestions.map((item, index) => (
                <RenderStatCard
                  item={item}
                  key={index}
                />
              ))}
            </div>
          </div>

          <div className='mt-10'>
            <h2 className="font-bold text-xl text-gray-800 mb-10">Subjective Doubts</h2>
            <div className='grid grid-cols-3 gap-4'>
              {SubjectiveDoubtsCount.map((item, index) => (
                <RenderStatCard
                  item={{
                    label: item?.subject,
                    value1: item?.SolvedCount,
                    value2: item?.SubmittedCount,
                  }}
                  type="subjective"
                  key={index}
                />
              ))}
            </div>
          </div>

          <div className="mt-10">
            <h2 className="font-bold text-xl text-gray-800 mb-10">Year Wise Add vs Edit</h2>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={filteredYearWiseAddEdit}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="adds" stroke="#8884d8" />
                <Line type="monotone" dataKey="edits" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="mt-10">
            <h2 className="font-bold text-xl text-gray-800 mb-10">Month Wise Add vs Edit</h2>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={filteredMonthWiseAddEdit}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="monthYear" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="adds" stroke="#8884d8" />
                <Line type="monotone" dataKey="edits" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="mt-10">
            <h2 className="font-bold text-xl text-gray-800 mb-10">Day Wise Add vs Edit</h2>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={filteredDayWiseAddEdit}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="adds" stroke="#8884d8" />
                <Line type="monotone" dataKey="edits" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}



<div className="mt-10">
            <h2 className="font-bold text-xl text-gray-800 mb-10">Top 10 Attempted Questions</h2>
            <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
              <thead>
                <tr className="bg-primary text-white font-bold">
                  <th className="py-2">Question ID</th>
                  <th className="py-2">Attempts</th>
                  <th className="py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {topTenAttempted.map((question, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">{question.questionId}</td>
                    <td className="border px-4 py-2">{question.count}</td>
                    <td className="border px-4 py-2 flex justify-center items-center">
                      <a href={`/editquestion/${question.questionId}`}><ManageIcon size='w-6 h-6' /></a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-10">
            <h2 className="font-bold text-xl text-gray-800 mb-10">Authors and Stats</h2>
            <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
              <thead>
                <tr className="bg-primary text-white font-bold">
                  <th className="py-2">Author</th>
                  <th className="py-2">Count</th>
                </tr>
              </thead>
              <tbody>
                {AuthorsAndStats.map((question, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">{question?.Author}</td>
                    <td className="border px-4 py-2">{question?.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>


          <div className='mt-10'>
            <h2 className="font-bold text-xl text-gray-800 mb-10">Reported Questions</h2>
            <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
              <thead>
                <tr className="bg-primary text-white font-bold">
                  <th className="py-2">Question ID</th>
                  <th className="py-2">Reported Count</th>
                  <th className="py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {ReportedQuestions.map((question, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">{question?.questionId}</td>
                    <td className="border px-4 py-2">{question?.totalReports}</td>
                    <td className="border px-4 py-2 flex justify-center items-center">
                      <a href={`/editquestion/${question.questionId}`}><ManageIcon size='w-6 h-6' /></a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

    </div>
  )
}

const RenderStatCard = ({
  item,
  key,
  type = "objective"
}: {
  item: { label: string; value1: number; value2?: number };
  key: number;
  type?: string;
}) => {
  return (
    <div
      className="w-full p-2 cursor-pointer"
      key={key}
    >
      <div className="flex flex-col px-6 py-10 overflow-hidden bg-white hover:bg-gradient-to-br hover:from-purple-400 hover:via-blue-400 hover:to-blue-500 rounded-xl shadow-lg duration-300 hover:shadow-2xl group transition-all ease-in-out">
        <div className="flex flex-row justify-between items-center">
          <div className="px-4 py-4 bg-gray-300  rounded-xl bg-opacity-30">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 group-hover:text-gray-50"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
              <path
                fill-rule="evenodd"
                d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div className="flex flex-col my-2 gap-2 ">
          <h1>
            {type === "subjective" ? "Solved Questions" : "Questions:"}
            <span className=" ml-4  text-xl font-bold text-gray-700 mt-16 group-hover:text-gray-50">
              {" "}
              {formatNumberWithCommas(item?.value1)}
            </span>
          </h1>
          {item.value2 && (
            <h1>
              {type === "subjective" ? "Available Questions" : "Added:"}
              <span className="ml-4  text-xl font-bold text-gray-700 mt-16 group-hover:text-gray-50">
                {" "}
                {formatNumberWithCommas(item?.value2)}
              </span>
            </h1>
          )}
        </div>
        <Divider />
        <div className="flex flex-row justify-between group-hover:text-gray-200">
          <p className="font-semibold text-lg text-gray-800">{item?.label}</p>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-indigo-600 group-hover:text-gray-200"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};