import React from "react";
export const TableSecondaryView = ({ order }) => {


  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div className="flex flex-col items-start justify-start gap-2">
        <div className="font-semibold text-lg text-gray-800">
          Products Purchased:
        </div>
        <div className="flex flex-col items-start justify-start gap-2">
          {/* {order.Products && order.Products !== "" ? (
            <>
              {order.Products.replace(/ \| /g, ", ")
                .split(", ")
                .map((product, index, arr) => (
                  <h1 key={index} className="text-md text-gray-700">
                    {product}
                    {index !== arr.length - 2 ? <span></span> : null}
                  </h1>
                ))}
            </>
          ) : (
            <div className="flex flex-col ustify-start gap-1">
              <h1 className="text-md font-semibold text-gray-700">
                MY OWN BUNDLE
              </h1>
              <h1 className="text-sm flex flex-wrap gap-2">
                {order.access && order.access.length > 0
                  ? order.access.map((access, index) => (
                    <h1 key={index} className="text-md text-gray-700">
                      {access}
                    </h1>
                  ))
                  : "No Access"}
              </h1>
              <h1 className="text-sm flex flex-wrap gap-2">
                Duration:{" "}
                {order.Duration
                  ? order.Duration === 0
                    ? "Till Exam 2024"
                    : order.Duration + " Months"
                  : "No Duration"}
              </h1>
            </div>
          )} */}
                      {order?.PRODUCTS?.join(",")}

        </div>

        <div className="flex items-center justify-start gap-2">
          <div className="font-semibold text-lg text-gray-800">
            Transaction ID:
          </div>
          <div className="text-md text-gray-700">
            {order.TransactionID
              ? order.TransactionID
              : "No Transaction ID"}
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start justify-start gap-2">
        <div className="font-semibold text-lg text-gray-800">
          Payment Proof:
        </div>
        <div className="flex items-center justify-start gap-2">
          <img
            className="object-contain h-78 w-72"
            src={`${order?.PaymentProof?.startsWith("http")
              ? order.PaymentProof
              : `https://premedpk-cdn.sgp1.digitaloceanspaces.com/PaymentProofs/${order._id}`}`}
            onClick={() => {
              window.open(
                order.PaymentProof.startsWith("http")
                  ? order.PaymentProof
                  : `https://premedpk-cdn.sgp1.digitaloceanspaces.com/PaymentProofs/${order._id}`
              );
            }} />
        </div>
      </div>

      <div className="flex flex-col items-start justify-start gap-2">
        <div className="font-semibold text-lg text-gray-800">
          Breakdown:
        </div>
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">Description</th>
              <th className="border border-gray-300 p-2">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 p-2">Total Amount</td>
              <td className="border border-gray-300 p-2">{order.BREAKDOWN?.TotalAmount || 0}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">Promotional Amount</td>
              <td className="border border-gray-300 p-2">{order.BREAKDOWN?.PromotionalAmount || 0}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">Promotional Discount</td>
              <td className="border border-gray-300 p-2">{order.BREAKDOWN?.PromotionalDiscount || 0}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">Coupon Discount Amount</td>
              <td className="border border-gray-300 p-2">{order.BREAKDOWN?.CouponDiscountAmount || 0}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">Coupon Discount</td>
              <td className="border border-gray-300 p-2">{order.BREAKDOWN?.CouponDiscount || 0}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">Total Discount</td>
              <td className="border border-gray-300 p-2">{order.BREAKDOWN?.TotalDiscount || 0}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">Grand Total</td>
              <td className="border border-gray-300 p-2">{order.BREAKDOWN?.GrandTotal || 0}</td>
            </tr>
          </tbody>
        </table>
      </div>

      
{/* 
      BREAKDOWN: {
    TotalAmount: Number,
    PromotionalAmount: Number,
    PromotionalDiscount: Number,
    CouponDiscountAmount: Number,
    CouponDiscount: Number,
    TotalDiscount: Number,
    GrandTotal: Number
  }, */}

    </div>
  );
};