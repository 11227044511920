import { LoadingIconFilled, LoadingIconOutlined } from "./Icons";

interface ButtonProps {
  handleClick?: any;
  children?: string | React.ReactNode;
  icon?: React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  type?: "danger" | "warning" | "success" | "Submit";
  largeBtn?: boolean;
  width?: string;
  margin?: boolean;
  height?: string;
  onHoverBgFilled?: boolean;
}

export const ButtonFill = (props: ButtonProps) => {
  const isDisabled = props.disabled !== undefined ? props.disabled : false;

  return (
    <button
      type={props?.type === "Submit" ? "submit" : "button"}
      className={`"text-white bg-primary focus:ring-4 focus:outline-none focus:ring-primary-300 shadow-none font-medium rounded-lg ${
        props?.largeBtn ? " text-lg px-8 py-3 " : " text-sm px-5 py-2.5 mt-2"
      }  
      hover:shadow-none hover:bg-opacity-90 hover:scale-1
      text-center mb-2 transition-all ease-in-out disabled:bg-rose-200 disabled:cursor-not-allowed"`}
      disabled={isDisabled}
      onClick={() => {
        if (props?.isLoading) return;
        if (props?.type === "Submit") return;
        props.handleClick();
      }}
    >
      {" "}
      {props.isLoading ? (
        <>
          <div role="status" className="flex items-center space-x-2">
            <span className="relative text-base font-semibold text-white flex items-center space-x-3">
              Loading
            </span>
            <LoadingIconFilled />
          </div>
        </>
      ) : (
        <div
          className={`relative flex items-center ${
            props.children && props.icon && " space-x-3 "
          }  text-white`}
        >
          {props.icon}
          {props.children && (
            <span className=" text-base font-semibold">{props.children}</span>
          )}
        </div>
      )}
    </button>
  );
};

interface ButtonOutlinedProps {
  handleClick: () => void;
  children?: string | React.ReactNode;
  icon?: React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  width?: string;
  margin?: boolean;
  height?: string;
  onHoverBgFilled?: boolean;
  type?: "button" | "reset" | "submit";
}

export const ButtonOutlined = (props: ButtonOutlinedProps) => {
  const isDisabled = props.disabled !== undefined ? props.disabled : false;

  return (
    <button
      className={`relative flex ${
        props?.height ? props.height : " h-12 "
      } items-center justify-center px-8 before:absolute before:inset-0 before:rounded-lg before:border  before:bg-gradient-to-b before:transition before:duration-300 before:scale-95 hover:before:scale-100 ${
        props?.onHoverBgFilled
          ? " hover:before:bg-primary  hover:before:border-primary"
          : " before:border-gray-200 before:bg-gray-50 "
      } active:duration-75 active:before:scale-95 disabled:opacity-70 disabled:cursor-not-allowed disabled:bg-whiter group  ${
        props.width ? props.width : " sm:w-max w-full "
      }  ${props.margin ? "my-2" : " "} `}
      onClick={() => {
        if (props?.isLoading) return;
        props.handleClick();
      }}
      type={props?.type ? props?.type : "button"}
      disabled={isDisabled}
    >
      {props.isLoading ? (
        <>
          <div role="status" className="flex items-center space-x-2">
            <span className="relative text-base font-semibold text-primary flex items-center space-x-3">
              Loading
            </span>
            <LoadingIconOutlined />
          </div>
        </>
      ) : (
        <div
          className={`relative flex items-center ${
            props.children && props.icon && " space-x-3 "
          }
            
            ${
              props?.onHoverBgFilled
                ? " text-primary group-hover:text-white "
                : " text-primary "
            }
  
            
            `}
        >
          {props.icon}
          {props.children && (
            <span className=" text-base font-semibold">{props.children}</span>
          )}
        </div>
      )}
    </button>
  );
};

interface GlobalPublishButtonProps {
  isActive: boolean;
  onChange: (id: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const GlobalPublishButton: React.FC<GlobalPublishButtonProps> = ({
  isActive,
  onChange,
  disabled,
}) => {
  return (
    <label className="flex items-center flex-start cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          className="sr-only"
          checked={isActive}
          onChange={(event) => onChange(event)}
          disabled={disabled}
        />
        <div
          className={`block 
                      ${isActive ? "bg-green-400" : "bg-gray-200"}
                      w-12 h-6 rounded-full`}
        ></div>
        <div
          className={`dot absolute 
                      ${isActive ? "left-1 top-1" : "left-7 top-1"}
                      bg-white w-4 h-4 rounded-full transition`}
        ></div>
      </div>
    </label>
  );
};

export default GlobalPublishButton;
