import React, { useEffect, useState } from "react";
import { Select, DatePicker, DatePickerProps } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import CustomDropDown from "shared-components/ui/CustomDropDown";

const { RangePicker } = DatePicker;

const TargetModal = ({ modal, allUsers, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    userIds: [],
    target: "",
    cashPrize: "",
    startDate: null,
    endDate: null,
    userType: "All Users",
    limitTime: "30 Days Limit",
  };
  const [formData, setFormData] = useState(initialState);

  const resetForm = () => {
    setFormData(initialState);
    closeModal();
  };

  useEffect(() => {
    if (allUsers?.length > 0) {
      const allUserIds = allUsers?.map((e: any) => e?.email);
      const dateRange = get30DayRange();
      setFormData({
        ...formData,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        userIds: allUserIds,
      });
    }
  }, [allUsers]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const get30DayRange = () => {
    const currentDate = new Date();
    const startDate = formatDate(new Date(currentDate));
    const endDate = formatDate(
      new Date(currentDate.setDate(currentDate.getDate() + 30))
    );
    return { startDate, endDate };
  };
  const handleDateChange = (value: string) => {
    const dateRange = get30DayRange();
    if (value === "30 Days Limit") {
      setFormData({
        ...formData,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        limitTime: value,
      });
    } else {
      setFormData({
        ...formData,
        startDate: dateRange.startDate,
        endDate: "",
        limitTime: value,
      });
    }
  };

  const validateForm = (formData) => {
    const { userIds, target, cashPrize, startDate } = formData;

    if (!userIds || userIds.length === 0) {
      toast.error("Please select at least one user.");
      return false;
    }
    if (!target || target <= 0) {
      toast.error("Please enter a target value.");
      return false;
    }
    if (!cashPrize || cashPrize <= 0) {
      toast.error("Please enter a cash prize amount.");
      return false;
    }
    if (!startDate) {
      toast.error("Please select a start date.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!validateForm(formData)) {
      return;
    }

    try {
      setIsLoading(true);
      const body = formData;
      if (formData?.userIds) {
        //converting emails into userId so we can update user efficiently by findByIdAdnUpdate
        const getUsers = allUsers?.filter((user) =>
          formData?.userIds.includes(user?.email)
        );
        const ids = getUsers?.map((user: any) => user?._id);
        body.userIds = ids;
      }
      console.log("Body: ", body);
      const response = await CommissionAPI("createTarget", [], body);
      console.log("response: ", response);
      if (response?.data?.success) {
        toast.success("Target created successfully.");
        resetForm();
      }
    } catch (error) {
      toast.error("Failed to create target.");
    } finally {
      setIsLoading(false);
    }
  };
  const handleSelectUserType = (value: string) => {
    let allUserIds = [];
    if (value === "All Users") {
      allUserIds = allUsers?.map((e: any) => e?.email);
    } else {
      allUserIds = [];
    }
    setFormData({
      ...formData,
      userType: value,
      userIds: allUserIds,
    });
  };

  console.log("formData: ", formData);
  return (
    <div
      className={` ${
        modal?.isModalOpen ? "" : "hidden"
      } fixed inset-0 z-50 flex items-center justify-center bg-gray-200/40 backdrop-blur-sm`}
    >
      <Toaster />
      <div className="bg-white rounded-lg p-8 w-full max-w-lg">
        <h2 className="text-xl font-bold mb-4">Create Target</h2>
        <form onSubmit={handleSubmit}>
          <div className="flex items-center mb-2 space-x-3 w-full">
            <CustomDropDown
              label="Select Users"
              options={["All Users", "Specific Users"]}
              onSelect={(value, name) => {
                handleSelectUserType(value);
              }}
              width="w-full"
              value={formData.userType}
              isError={false}
              errorMessage={""}
            />
          </div>

          {/* User Selection */}
          <div className="mb-2">
            <label className="block mb-1 font-semibold">Select Users</label>
            <span className="inline-block mb-1 text-sm">
              Number of users selected: {formData?.userIds?.length || 0}
            </span>

            <Select
              mode="multiple"
              placeholder="Select user(s)"
              onChange={(value) => setFormData({ ...formData, userIds: value })}
              className="w-full mb-4"
              value={formData.userIds}
              // disabled={formData?.userType === "All Users"}
            >
              {allUsers.map((user: any, index: number) => (
                <Select.Option key={index} value={user.userId}>
                  {user.email}
                </Select.Option>
              ))}
            </Select>
          </div>

          {/* Target */}
          <label className="block mb-2 font-semibold">Target</label>
          <input
            type="number"
            name="target"
            value={formData.target}
            onChange={handleInputChange}
            placeholder="Enter target"
            className="w-full mb-4 p-2 border rounded"
          />

          {/* Cash Prize */}
          <label className="block mb-2 font-semibold">Cash Prize</label>
          <input
            type="number"
            name="cashPrize"
            value={formData.cashPrize}
            onChange={handleInputChange}
            placeholder="Enter cash prize"
            className="w-full mb-4 p-2 border rounded"
          />

          <div className="flex items-center mb-2 space-x-3 w-full">
            <CustomDropDown
              label="Select Limit"
              options={["30 Days Limit", "No Limit"]}
              onSelect={(value, name) => {
                handleDateChange(value);
              }}
              width="w-full"
              value={formData.limitTime}
              isError={false}
              errorMessage={""}
            />
          </div>

          <div className="flex mb-2 space-x-3">
            <input
              type="date"
              value={formData?.startDate}
              disabled
              className="w-1/2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5"
            />

            <input
              type="date"
              value={formData?.endDate}
              disabled
              className="w-1/2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5"
            />
          </div>
          {/* Submit Button */}
          <div className="flex justify-center space-x-4">
            <ButtonOutlined handleClick={() => resetForm()}>
              Cancel
            </ButtonOutlined>
            <ButtonFill type="Submit" disabled={isLoading}>
              {isLoading ? "Loading..." : "Submit"}
            </ButtonFill>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TargetModal;
