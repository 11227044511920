import { Table } from "antd"

export const commoncolumns = [
    {
        title: "Thumbnail",
        dataIndex: "thumbnailImageUrl",
        flex: 1,
        key: "thumbnailImageUrl",
       
      },
      
      {
        title: "Board",
        dataIndex: "board",
        key: "board",
        flex: 1,
      },
      {
        title: "Access",
        dataIndex: "access",
        key: "access",
        flex: 1,
      },
      {
        title: "Topic",
        dataIndex: "topicName",
        key: "topicName",
        flex: 1,
      },
      {
        title: "SubTopic",
        dataIndex: "subTopicName",
        key: "subTopicName",
        flex: 1,
      },
      
      {
        title: "Province",
        dataIndex: "province",
        key: "province",
        flex: 1,
      },
      {
        title:process.env.REACT_APP_CATEGORY === 'MEDSCHOOL' ?"Discipline": "Subject",
        dataIndex: "subject",
        key: "subject",
        flex: 1,
      },
      Table.EXPAND_COLUMN,
      {
        title: "Description",
        dataIndex: "desc",
        key: "desc",
        flex: 1,
      },
      Table.SELECTION_COLUMN,
      {
        title: "IS ACTIVE",
        dataIndex: "isPublished",
        key: "isPublished",
        align: "center",
      width: 100,
       
      },
      {
        title: "ACTIONS",
        key: "actions",
        dataIndex: "action",
        align: "center",
        width: 100, 
      },
]
