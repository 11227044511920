import { Popconfirm } from "antd";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { AccessAPI } from "../../(apis)/(shared-apis)/admin-access-apis";
import { ButtonFill } from "../../shared-components/ui/CustomButtons";
import { SearchIcon } from "../../shared-components/ui/Icons";
import AddNewEmployee from "./components/add-new-employee";
import PermissionModal from "./components/permission-modal";
import AddUserForm from "./components/add-modal";

export default function AdminUsers() {
    const [adminusers, SetAdminusers] = useState([]);
    const [displayedAdminUsers, SetDisplayedAdminUsers] = useState([]);
    const [userEmails, SetUserEmails] = useState([]);
    const [addNewEmployeeVisiible, SetAddNewEmployeeVisible] = useState(false);
    const [permissionsModalOpen, SetPermissionsModalOpen] = useState(false);

    const [searchQuery, SetSearchQuery] = useState("");

    const [selectedUser, SetSelectedUser] = useState();

    const [isLoading, SetIsLoading] = useState(true);
    useEffect(() => {
        FetchData();
        AccessAPI("getUsersEmails").then((res) => {
            SetUserEmails(res.data);
        });
    }, []);

    function FetchData() {
        AccessAPI("getAdminUsers").then((res) => {
            SetDisplayedAdminUsers(res.data);
            SetAdminusers(res.data);
        });
        SetIsLoading(false);
    }


    const handleSearchQueryChange = (e) => {
        SetSearchQuery(e.target.value);
        if (e.target.value === "") {
            SetDisplayedAdminUsers(adminusers);
            return;
        }
        const filteredAdminUsers = adminusers.filter((a) =>
            a.username.toLowerCase().includes(e.target.value.toLowerCase())
        );
        SetDisplayedAdminUsers(filteredAdminUsers);
    }


    const [createAdminUser, setCreateAdminUser] = useState(false);

    return (
        <>
            <div className='w-[95%] mx-auto h-full'>
                <h1 className='text-3xl text-gray-700 font-bold'>Admin Access Management</h1>
                <section className="my-5 antialiased">
                    <div className="mx-auto">
                        {isLoading ? (
                            <div>Loading...</div>
                        ) : (
                            <div className="bg-white shadow-md sm:rounded-lg">
                                <TableTop
                                    searchQuery={searchQuery}
                                    handleSearchQueryChange={handleSearchQueryChange}
                                    SetAddNewEmployeeVisible={SetAddNewEmployeeVisible}
                                    setCreateAdminUser={setCreateAdminUser}
                                />
                                <div className="overflow-x-auto">
                                    <table className="w-full mb-20 text-sm text-left text-gray-500">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-4 py-4">
                                                    Full Name
                                                </th>
                                                <th scope="col" className="px-4 py-4">
                                                    Username
                                                </th>
                                                <th scope="col" className="px-4 py-4 text-left">
                                                    Role
                                                </th>
                                                <th scope="col" className="px-4 py-4 text-left">
                                                    Phone
                                                </th>
                                                <th scope="col" className="px-4 py-4 text-center">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayedAdminUsers
                                                && displayedAdminUsers.map((user) => (
                                                    <tr className="border-b" key={user.username}>
                                                        <td className="px-4 py-3">{user.fullname}</td>
                                                        <td className="px-4 py-3">{user.username}</td>
                                                        <td className="px-4 py-3">{user.admin.role + " - " + user.admin.dept}</td>
                                                        <td className="px-4 py-3">{user.phonenumber}</td>
                                                        <td className="px-4 py-3 text-center flex justify-center space-x-2">
                                                            <Popconfirm
                                                                placement="leftTop"
                                                                title={"Are you sure you want remove admin access for this account"}
                                                                onConfirm={() => {
                                                                    AccessAPI("removeAdminAccess", [""], { username: user.username }).then((res) => {
                                                                        toast.success("Admin access removed successfully");
                                                                        FetchData();
                                                                    })
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <button
                                                                    className="text-red-500 hover:text-red-600 cursor-pointer mr-2"
                                                                >
                                                                    Delete
                                                                </button>
                                                            </Popconfirm>
                                                            {" "}|
                                                            <button
                                                                className="text-blue-500 hover:text-blue-600 cursor-pointer"
                                                                onClick={() => {
                                                                    SetSelectedUser(user);
                                                                    SetPermissionsModalOpen(true);
                                                                }}
                                                            >
                                                                Permissions
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>

            <AddUserForm
                active={createAdminUser}
                setModal={setCreateAdminUser}
                onCancel={() => {
                    setCreateAdminUser(false);
                }}
                onConfirm={() => {
                    setCreateAdminUser(true);
                }}
            />


            <AddNewEmployee
                modalVisible={addNewEmployeeVisiible}
                onCloseModal={() => {
                    SetAddNewEmployeeVisible(false);
                    FetchData();
                }}
                existingAdminUsers={adminusers}
                usersEmails={userEmails}
            />


            <PermissionModal
                ShowModel={permissionsModalOpen}
                setShowModel={SetPermissionsModalOpen}
                selectedUser={selectedUser}
                FetchData={FetchData}
            />

            <Toaster />
        </>
    );
}



export const TableTop = ({
    searchQuery,
    handleSearchQueryChange,
    SetAddNewEmployeeVisible,
    setCreateAdminUser
}) => {

    return (
        <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
                <form className="flex items-center">
                    <label htmlFor="simple-search" className="sr-only">
                        Search
                    </label>
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <SearchIcon color="text-gray-400" />
                        </div>
                        <input
                            type="text"
                            id="simple-search"
                            className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
                            placeholder="Search"
                            required={true}
                            value={searchQuery}
                            onChange={handleSearchQueryChange}
                        />
                    </div>
                </form>
            </div>
            <div className="flex items-center justify-end w-full gap-2">
                <ButtonFill
                    handleClick={() => {
                        SetAddNewEmployeeVisible(true);
                    }}
                >
                    Give access to new employee
                </ButtonFill>
                    <ButtonFill handleClick={() => setCreateAdminUser(true)}>
                        Create & Give Admin Access
                    </ButtonFill>
            </div>
        </div>
    );
};
